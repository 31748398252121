<template>
  <v-app id='login' class='secondary'>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class='elevation-1 pa-3'>
              <v-card-text>
                <div class='layout column align-center'>
                  <img src='images/voice-tv-v.svg' alt='Vue Material Admin' width='180' height='180'>
                  <h1 class='flex my-4'>Membership Portal</h1>
                </div>
                <v-form>
                  <v-text-field
                    append-icon='phone'
                    name='phoneNumber'
                    label='Phone Number'
                    type='text'
                    v-model='phoneNumber'
                    :error='error'
                    :rules='[rules.required]' />
                  <div v-if='confirmationResult===null' id='recaptcha-container'></div>
                  <v-text-field append-icon='code' name='otpCode' label='Verify Code' type='text' v-model='otpCode'
                                v-if='confirmationResult!==null' />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn id='sign-in-btn' block color='grey' @click='signIn' :loading='loading'>Sign In</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar
        v-model='showResult'
        :timeout='2000'
        top>
        {{ result }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  data() {
    return {
      appVerifier: null,
      otpCode: '',
      phoneNumber: '',
      showResult: false,
      result: '',
      rules: {
        required: value => !!value || 'Required.',
      },
    }
  },
  computed: {
    error() {
      return this.$store.getters.getError
    },
    loading() {
      return this.$store.getters.getLoading
    },
    confirmationResult() {
      return this.$store.getters.getConfirmationResult
    },
  },
  mounted() {
    firebase.auth().useDeviceLanguage()
    this.appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')
  },
  methods: {
    signIn() {
      const vm = this
      if (this.confirmationResult === null) {
        if (!vm.phoneNumber) {
          vm.result = 'Phone number cannot be null.'
          vm.showResult = true
          return
        }
        if (!this.phoneNumber.includes('+66')) {
          this.phoneNumber = `+66${this.phoneNumber.substr(1, this.phoneNumber.length - 1)}`
        }
        this.$store.dispatch('userCodeRequest', { phoneNumber: this.phoneNumber, appVerifier: this.appVerifier })
      } else {
        if (!vm.otpCode) {
          vm.result = 'OTP code cannot be null.'
          vm.showResult = true
          return
        }
        this.$store.dispatch('userCodeSubmit', { otpCode: this.otpCode })
      }
    },
  },
}
</script>
<style scoped lang='css'>
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>
